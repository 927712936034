import { Menu } from "antd";
import { Text } from "@primer/react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledMenu = styled(Menu)`
  justify-content: center;
`;

export const StyledBackText = styled(Text)`
  display: flex;
  flex-basis: 100%;

  a {
    color: #000;
    display: inline-block;
    text-decoration: none;
  }
`;

export const AppNavBar = styled.header`
  align-items: center;
  border-bottom: 1px solid #d1d9e0;
  display: flex;
  justify-content: space-between;
  padding: 8px 128px 0 32px;
`;

export const LogoNavBarWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 4;
  gap: 10px;
`;

export const StyledLogo = styled.img`
  width: 240px;
`;

export const UserInfoNavBarWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 8px 32px 0 32px;
  width: 15%;
`;

export const StyledLink = styled(Link)`
  color: #000000;
  text-decoration: none;
`;

export const AppRoutesWrapper = styled.div`
  padding: 36px 0 0 0;
`;
