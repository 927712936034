import { Box } from "@primer/react";
import styled from "styled-components";
import {MenuItem, Sidebar} from "react-pro-sidebar";

export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  width: 100%;
`;

export const HeaderText = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  margin: 24px 0;
  text-align: center;
  color: #333;
`;

export const HeaderImage = styled.img`
  margin: 0 auto;
  padding: 24px 0;
  width: 60%;
`;

export const ChartImage = styled.img`
  margin: 0 auto;
  padding: 16px 0;
  width: 90%;
`;

export const DailyAverageChartWrapper = styled(Box)`
  grid-area: daily-average;
  padding: 32px;
`;


export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

export const HalfWidthImage = styled.img`
  flex: 1;
  width: 50%;
  max-width: 100%;
  height: 400px; /* Set a fixed height */
  padding: 10px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 80%;
  margin: auto;
  padding: 16px;
`;

export const ToggleContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const ToggleButton = styled.button`
  background-color: #4a90e2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #357abf;
  }
`;

export const TopLeftHeader = styled(HeaderText)`
  
  top: 10px;
  left: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.8); /* Adds a subtle background */
`;

// Adds margin to the HeaderText for spacing between it and the first chart
export const SectionHeader = styled(HeaderText)`
  margin-top: 60px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
`;

export const Divider = styled.hr`
  margin-top: 50px;
  border: none;
  border-top: 1px solid #ccc;
  width: 100%;
`;

export const ActiveMenuItem = styled(MenuItem)`
  background-color: #ddd;
  font-weight: bold;
`;

// Flex container for Sidebar and Main Content
export const ContentContainer = styled.div`
  display: flex;
`;

export const StyledSidebar = styled(Sidebar)`
  width: 200px;
  min-width: 200px;
  height: 100vh;
  background-color: #f4f4f4;
`;
