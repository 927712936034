import React, { useState } from 'react';
import {
  DetailsWrapper,
  RowWrapper,
  ToggleContainer,
  ToggleButton,
  ChartWrapper,
  HalfWidthImage,
  ChartImage,
  ImageRowWrapper,
  SectionHeader,
  ContentContainer,
  StyledSidebar,
  ActiveMenuItem
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import weekendAvgDaily from '../../assets/charts/WeekendAverageDailyTraffic.svg';
import weekendAvgHourly from '../../assets/charts/WeekendAverageHourlyTraffic.svg';
import weekdayAvgDaily from '../../assets/charts/WeekdayAverageDailyTraffic.svg';
import weekdayAvgHourly from '../../assets/charts/WeekdayAverageHourlyTraffic.svg';
import age from '../../assets/charts/age.svg';
import gender from '../../assets/charts/gender.svg';
import avgDailyTraffic from '../../assets/charts/dailyTraffic.svg';
import avgWeekendTrafficSVG from '../../assets/charts/avgWeekendTraffic.svg';
import avgWeekdayTrafficSVG from '../../assets/charts/avgWeekdayTraffic.svg';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import styled from 'styled-components';

type DetailsPropType = {
  property: any;
  timeframe?: string;
};

function Details(props: DetailsPropType) {
  const {
    property: { id = 123 },
    timeframe,
  } = props;

  const { uuid = '' } = useParams();
  const navigate = useNavigate();
  const [timeToggle, setTimeToggle] = useState('weekday');

  const handleTimeToggle = () => {
    setTimeToggle(prev => (prev === 'weekday' ? 'weekend' : 'weekday'));
  };
  const [view, setView] = useState('hourly'); // State for selected view

  return (
      <ContentContainer>
        <StyledSidebar>
          <Menu>
            {view === 'hourly' ? (
                <ActiveMenuItem onClick={() => setView('hourly')}>Hourly Averages</ActiveMenuItem>
            ) : (
                <MenuItem onClick={() => setView('hourly')}>Hourly Averages</MenuItem>
            )}
            {view === 'daily' ? (
                <ActiveMenuItem onClick={() => setView('daily')}>Daily Averages</ActiveMenuItem>
            ) : (
                <MenuItem onClick={() => setView('daily')}>Daily Averages</MenuItem>
            )}
            {view === 'age' ? (
                <ActiveMenuItem onClick={() => setView('age')}>Age Averages</ActiveMenuItem>
            ) : (
                <MenuItem onClick={() => setView('age')}>Age Averages</MenuItem>
            )}
            {view === 'gender' ? (
                <ActiveMenuItem onClick={() => setView('gender')}>Gender Averages</ActiveMenuItem>
            ) : (
                <MenuItem onClick={() => setView('gender')}>Gender Averages</MenuItem>
            )}
          </Menu>
        </StyledSidebar>

        <DetailsWrapper>
          {view === 'hourly' && (
              <>
                <ToggleContainer>
                  <ToggleButton onClick={handleTimeToggle}>
                    {timeToggle === 'weekday' ? 'Switch to Weekend' : 'Switch to Weekday'}
                  </ToggleButton>
                </ToggleContainer>
                <SectionHeader>
                  {timeToggle === 'weekday' ? 'Average Weekday Traffic for 171 W 57th St' :
                      'Average Weekend Traffic for 171 W 57th St'}
                </SectionHeader>

                <RowWrapper>
                  <ChartWrapper>
                    <ChartImage
                        src={timeToggle === 'weekday' ? avgWeekdayTrafficSVG : avgWeekendTrafficSVG}
                        alt={timeToggle === 'weekday' ? 'Weekday Traffic Chart' : 'Weekend Traffic Chart'}
                    />
                  </ChartWrapper>
                </RowWrapper>

                <ImageRowWrapper>
                  {timeToggle === 'weekday' ? (
                      <>
                        <HalfWidthImage src={weekdayAvgDaily} alt="Weekday Daily Average People" />
                        <HalfWidthImage src={weekdayAvgHourly} alt="Weekday Hourly Average People" />
                      </>
                  ) : (
                      <>
                        <HalfWidthImage src={weekendAvgDaily} alt="Weekend Daily Average People" />
                        <HalfWidthImage src={weekendAvgHourly} alt="Weekend Hourly Average People" />
                      </>
                  )}
                </ImageRowWrapper>
              </>
          )}

          {view === 'daily' && (
              <>
                <SectionHeader>Average Daily Traffic for 171 W 57th St</SectionHeader>
              <RowWrapper>
                <ChartWrapper>
                  <ChartImage src={avgDailyTraffic} alt="Daily Traffic Chart" />
                </ChartWrapper>
              </RowWrapper>
              </>
          )}

          {view === 'age' && (
              <>
                <SectionHeader>Age Averages for 171 W 57th St</SectionHeader>
                <RowWrapper>
                  <ChartWrapper>
                    <ChartImage src={age} alt="Age Averages Chart" />
                  </ChartWrapper>
                </RowWrapper>
              </>
          )}

          {view === 'gender' && (
              <>
                <SectionHeader>Gender Averages for 171 W 57th St</SectionHeader>
                <RowWrapper>
                  <ChartWrapper>
                    <ChartImage src={gender} alt="Gender Averages Chart" />
                  </ChartWrapper>
                </RowWrapper>

              </>
          )}
        </DetailsWrapper>
      </ContentContainer>
  );
}

export default Details;
